import React from 'react'
import PropTypes from 'prop-types'

const Footer = ({ copyright, repositoryName, repositoryLink }) => (
  <footer className="footer">
    {copyright}
  </footer>
)

Footer.propTypes = {
  copyright: PropTypes.string,
  repositoryName: PropTypes.string,
  repositoryLink: PropTypes.string
}

export default Footer
