import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { Helmet } from 'react-helmet'
import Link from 'gatsby-link'
import Header from '../../components/header.js'
import Footer from '../../components/footer.js'
import Category from '../../components/category.js'
import Tag from '../../components/tag.js'

const MAX_POSTS = 10

const IndexPage = ({ data }) => (
  <div className="page">
    <div className="container">
      <Helmet title={data.site.siteMetadata.blogTitle} />
      <Header
        siteTitle={data.site.siteMetadata.blogTitle}
        siteSubTitle={data.site.siteMetadata.subtitle}
      />
      <div className="content">
        {data.allMarkdownRemark.edges
          .filter(({ node }) => !node.frontmatter.single)
          .slice(0, MAX_POSTS)
          .map(({ node: post }) => {
            const { frontmatter: meta } = post
            return (
              <div className="post" key={post.id}>
                <h1>
                  <Link to={meta.path}>{meta.title}</Link>
                </h1>
                <div className="meta">
                  <span className="value">
                    <i className="icon-calendar" />
                    {meta.date}
                  </span>
                  <Category categories={meta.categories} />
                  <Tag tags={meta.tags} />
                </div>
                <p>{meta.excerpt}</p>
              </div>
            )
          })}
      </div>
    </div>
    <Footer
      copyright={data.site.siteMetadata.copyright}
      repositoryName={data.site.siteMetadata.repositoryName}
      repositoryLink={data.site.siteMetadata.repositoryLink}
    />
  </div>
)

IndexPage.propTypes = {
  data: PropTypes.object,
}

export const query = graphql`
  query IndexQuery {
    site {
      siteMetadata {
        blogTitle
        subtitle
        description
        copyright
        repositoryName
        repositoryLink
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      totalCount
      edges {
        node {
          id
          frontmatter {
            title
            date(formatString: "MMMM DD, YYYY")
            path
            categories
            tags
            excerpt
            single
          }
        }
      }
    }
  }
`

export default IndexPage
