import React from 'react'
import PropTypes from 'prop-types'
import Link from 'gatsby-link'

const Category = ({ categories }) => {
  if (!categories || categories.length === 0) {
    return null
  }

  return (
    <span className="value">
      <i className="icon-folder" />
      {categories.map((category, index) => {
        return (
          <span key={index}>
            {index === 0 ? '' : ', '}
            <Link to={`/blog/category/${category}`}>{category}</Link>
          </span>
        )
      })}
    </span>
  )
}

Category.propTypes = {
  categories: PropTypes.array
}

export default Category
