import React from 'react'
import PropTypes from 'prop-types'
import Link from 'gatsby-link'

const Tag = ({ tags }) => {
  if (!tags || tags.length === 0) {
    return null
  }

  return (
    <span className="value">
      <i className="icon-tag" />
      {tags.map((tag, index) => {
        return (
          <span key={index}>
            {index === 0 ? '' : ', '}
            <Link to={`/blog/tag/${tag}`}>{tag}</Link>
          </span>
        )
      })}
    </span>
  )
}

Tag.propTypes = {
  tags: PropTypes.array,
}

export default Tag
